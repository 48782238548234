import React from 'react';
import './../App.css';
import { Input, Button, Icon, Select, Modal } from 'antd';
import queryString from 'query-string';
import { famousList, countryList, salutationList } from './list';
import Cookies from 'universal-cookie';
import {SERVER_URL, MATCH_NON_ENGLISH, PHONE_REG} from '../constant'
import DateAddress from '../components/dateAddress';
import Logo from '../components/logo';
const cookies = new Cookies();
const { Option } = Select;

class Register_v2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      salutation: '',
      name: '',
      firstName: '',
      lastName: '',
      email: '',
      companyEmail: '',
      designation: '',
      telephone: '',
      company: '',
      address1: '',
      address2: '',
      postCode: '',
      state: '',
      city: '',
      country: 'Malaysia',
      // website: '',
      officeTel: '',
      appendedVisitorNumber: 0,
      appendedVisitorDOM: [],
      appendedVisitor: [],
      nameRequired: false,
      emailRequired: false,
      designationRequired: false,
      telephoneRequired: false,
      companyEmailRequired: false,
      companyRequired: false,
      address1Required: false,
      postCodeRequired: false,
      stateRequired: false,
      countryRequired: false,
      // websiteRequired: false,
      firstNameRequired: false,
      lastNameRequired: false,
      cityRequired: false,
      emailDisabled: true,
      companyEmailDisabled: false,
      companyDomain: '',
      companyEmailExist: false,
      emailExists: false,
      companyEmailExists: false,
      originalEmail: '',
      groupRegistration: false,
      nonEnglishInputs: [],
      invalidLengthText: [],
      salutationRequired: false,
      vType: 'Visitor'
    };
  }

  navigateP2() {
    let errorPage1 = false
    let duplicatedEmail = this.checkEmailAdded()
    let isTextInvalidLength = this.checkTextLength()

    if (this.state.email.trim() === '' && !this.state.platformID) {
      this.setState({emailRequired: true})
      errorPage1 = true
    }
    if (this.state.designation.trim() === '') {
      this.setState({designationRequired: true})
      errorPage1 = true
    }
    if (this.state.firstName.trim() === '' || this.state.nonEnglishInputs.includes('firstName')) {
      this.setState({'firstNameRequired': true})
      errorPage1 = false
    }
    if (this.state.lastName.trim() === '' || this.state.nonEnglishInputs.includes('lastName')) {
      this.setState({'lastNameRequired': true})
      errorPage1 = false
    }
    if (this.state.telephone.trim() === '' ) {
      this.setState({telephoneRequired: true})
      errorPage1 = true
    }
    if (this.state.company.trim() === '') {
      this.setState({companyRequired: true})
      errorPage1 = true
    }
    if (this.state.address1.trim() === '') {
      this.setState({address1Required: true})
      errorPage1 = true
    }
    if (this.state.postCode.trim() === '') {
      this.setState({postCodeRequired: true})
      errorPage1 = true
    }
    if (this.state.state.trim() === '') {
      this.setState({stateRequired: true})
      errorPage1 = true
    }
    if (!this.state.country.trim()) {
      this.setState({countryRequired: true})
      errorPage1 = true
    }
    if (!this.state.city.trim()) {
      this.setState({cityRequired: true})
      errorPage1 = true
    }
    // if (this.state.companyEmailExists === true) {
    //   errorPage1 = true
    // }
    // if (this.state.groupRegistration && this.state.companyEmail === '') {
    //   this.setState({companyEmailRequired: true})
    //   errorPage1 = true
    // }
    if (!this.state.salutation) {
      this.setState({salutationRequired: true})
      errorPage1 = true
    }
    if (duplicatedEmail || this.state.nonEnglishInputs.length > 0 || isTextInvalidLength === false) {
      errorPage1 = true
    }
    for (let i = 0; i < this.state.appendedVisitor.length; i++) {
      if (this.state.appendedVisitor[i].name === '') {
        let newState = Object.assign({}, this.state.appendedVisitor);
        newState[i].nameRequired = true
        this.setState(newState)
        errorPage1 = true
      }
      if (this.state.appendedVisitor[i].email === '') {
        let newState = Object.assign({}, this.state.appendedVisitor);
        newState[i].emailRequired = true
        this.setState(newState)
        errorPage1 = true
      }
      if (this.state.appendedVisitor[i].designation === '') {
        let newState = Object.assign({}, this.state.appendedVisitor);
        newState[i].designationRequired = true
        this.setState(newState)
        errorPage1 = true
      }
      if (this.state.appendedVisitor[i].telephone === '') {
        let newState = Object.assign({}, this.state.appendedVisitor);
        newState[i].telephoneRequired = true
        this.setState(newState)
        errorPage1 = true
      }
      if (this.state.appendedVisitor[i].emailError === true) {
        errorPage1 = true
      }
    }
    if (!errorPage1) {
      const that = this
      let i = this.state.i
      let platform
      if (this.props.location.state) {
        i = this.props.location.state.i
        platform = this.props.location.state.platform
      }
      const query = queryString.parse(this.props.location.search)
      if (Object.entries(query).length !== 0) {
        i = query.i
        platform = 'Web'
      }
      const name = this.state.firstName +  ' ' + this.state.lastName
      const updates = [{
        i,
        platform,
        name: name,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        companyEmail: this.state.companyEmail,
        designation: this.state.designation,
        telephone: this.state.telephone,
        company: this.state.company,
        address1: this.state.address1,
        address2: this.state.address2,
        postCode: this.state.postCode,
        state: this.state.state,
        country: this.state.country,
        // website: this.state.website,
        city: this.state.city,
        salutation: this.state.salutation,
        officeTel: this.state.officeTel
      }]
      for (let i = 0; i < this.state.appendedVisitor.length; i++) {
        updates.push({
          i,
          platform,
          name: that.state.appendedVisitor[i].name,
          email: that.state.appendedVisitor[i].email + "@" + that.state.companyDomain,
          companyEmail: that.state.appendedVisitor[i].email + "@" + that.state.companyDomain,
          designation: that.state.appendedVisitor[i].designation,
          telephone: that.state.appendedVisitor[i].telephone,
          company: that.state.company,
          address1: that.state.address1,
          address2: that.state.address2,
          postCode: that.state.postCode,
          state: that.state.state,
          country: that.state.country,
          // website: that.state.website,
          city: that.state.city,
          salutation: that.state.salutation,
          officeTel: that.state.officeTel
        })
      }
      cookies.set('mteRegistration', {updates});
      this.props.history.push('/registerp2', {updates})
    }
  }

  handleChange(field, value) {
    this.setState({[field]: value})
    this.setState({['invalid-length-' + field]: false})
    const match = MATCH_NON_ENGLISH.test(value)
    const array = this.state.nonEnglishInputs
    const index = array.indexOf(field)
    // const array2 = this.state.invalidLengthText
    // const index2 = array2.indexOf(field)
    // const checkList = ['designation', 'telephone', 'company', 'address1', 'postCode', 'state', 'country', 'city']
    // if (index2 >= 0 && (( value.length >= 5 && field !== 'firstName' && field !== 'lastName') || (value.length >= 1 && (field === 'firstName' || field === 'lastName')))) {
    //   array2.splice(index2, 1)
    //   this.setState({'invalidLengthText': array2})
    // } else if (index2 < 0 && value.length > 0 && ((value.length < 5 && field !== 'firstName' && field !== 'lastName') || (value.length < 1 && (field === 'firstName' || field === 'lastName')))) {
    //   array2.push(field)
    //   this.setState({'invalidLengthText': array2})
    // }
    if ( index >= 0 && !match) {
      array.splice(index, 1)
      this.setState({'nonEnglishInputs': array})
    } else if (index < 0 && match) {
      array.push(field)
      this.setState({'nonEnglishInputs': array})
    }

    if (field === 'companyEmail') {
      const companyDomain = value.includes("@") ? value.replace(/.*@/, "") : '';
      this.setState({companyDomain})
    }
    if (field === 'salutation') {
      this.setState({salutationRequired: ''})
    }
  }

  handleText = (index, field, event) => {
    let appendedVisitor = [...this.state.appendedVisitor]
    appendedVisitor[index][field] = event.target.value
    if (field === 'email') {
      appendedVisitor[index][field] = event.target.value.includes("@") ? appendedVisitor[index][field].replace(/@.*$/,"") : event.target.value
    }
    this.setState({appendedVisitor})
  }

  removeVisitor(index) {
    let appendedVisitor = [
      ...this.state.appendedVisitor.slice(0, index),
      ...this.state.appendedVisitor.slice(index + 1)
    ]
    this.setState({appendedVisitor})
  }

  addVisitor() {
    const duplicateEmail = this.checkEmailAdded()
    if (!duplicateEmail) {
      const newNumber = this.state.appendedVisitorNumber + 1
      const newArray = this.state.appendedVisitor.concat({
        name: '',
        email: '',
        designation: '',
        telephone: '',
      })
      if (newArray.length <= 10) {
        this.setState({appendedVisitorNumber: newNumber});
        this.setState({appendedVisitor: newArray});
      }
    }
  }

  async componentDidMount() {
    let i
    const cookieOldArray = []
    let isCookieArray = false;
    if (typeof cookies.get('mteRegistration') !== 'undefined') {
      const mteRegistrationCookie = cookies.get('mteRegistration')
      const cookieArray = mteRegistrationCookie.updates
      for (let j = 0; j < cookieArray.length; j++) {
        if (j === 0) {
          i = cookieArray[j].i
          cookieArray[j].telephone && this.setState({telephone: cookieArray[j].telephone})
          cookieArray[j].company && this.setState({company: cookieArray[j].company})
          cookieArray[j].address1 && this.setState({address1: cookieArray[j].address1})
          cookieArray[j].address2 && this.setState({address2: cookieArray[j].address2})
          cookieArray[j].postCode && this.setState({postCode: cookieArray[j].postCode})
          cookieArray[j].state && this.setState({state: cookieArray[j].state})
          cookieArray[j].country && this.setState({country: cookieArray[j].country})
          cookieArray[j].city && this.setState({city: cookieArray[j].city})
          // cookieArray[j].website && this.setState({website: cookieArray[j].website})
          // cookieArray[j].name && this.setState({name: cookieArray[j].name})
          cookieArray[j].firstName && this.setState({firstName: cookieArray[j].firstName})
          cookieArray[j].lastName && this.setState({lastName: cookieArray[j].lastName})
          cookieArray[j].email && this.setState({email: cookieArray[j].email})
          cookieArray[j].companyEmail && this.setState({groupRegistration: true})
          cookieArray[j].companyEmail && this.setState({companyDomain: cookieArray[j].companyEmail.replace(/.*@/, "")})
          cookieArray[j].companyEmail && this.setState({companyEmail: cookieArray[j].companyEmail})
          cookieArray[j].designation && this.setState({designation: cookieArray[j].designation})
          cookieArray[j].salutation && this.setState({salutation: cookieArray[j].salutation})
          cookieArray[j].officeTel && this.setState({officeTel: cookieArray[j].officeTel})
        } else {
          cookieOldArray.push({
            name: cookieArray[j].name,
            // firstName: cookieArray[j].firstName,
            // lastName: cookieArray[j].lastName,
            email: cookieArray[j].email.slice(0, cookieArray[j].email.indexOf('@')),
            designation: cookieArray[j].designation,
            telephone: cookieArray[j].telephone,
          })
        }
      }
      isCookieArray = true
      this.setState({appendedVisitor: cookieOldArray});
    }
    if (this.props.location.state) {
      i = this.props.location.state.i
    }
    const query = queryString.parse(this.props.location.search)
    if (Object.entries(query).length !== 0) {
      i = query.i
    }
    if (i) {
      this.setState({i})
    }
    if (typeof i !== 'undefined' && i !== '' && !isCookieArray) {
      this.setState({i})
      fetch(`${SERVER_URL}/visitor?i=${i}`, {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => response.json())
        .then((json) => {
          json.body.platformID && this.setState({platformID: json.body.platformID})
          json.body.name && this.setState({name: json.body.name})
          // json.body.firstName && this.setState({firstName: json.body.firstName})
          // json.body.lastName && this.setState({lastName: json.body.lastName})
          json.body.vType && this.setState({vType: json.body.vType})
          json.body.email && this.setState({email: json.body.email})
          if (json.body.isReturning) {
            this.setState({salutation: json.body.salutation || ''})
            this.setState({firstName: json.body.firstName || ''})
            this.setState({lastName: json.body.lastName || ''})
            this.setState({designation: json.body.designation || ''})
            this.setState({company: json.body.company || ''})
            this.setState({address1: json.body.address1 || ''})
            this.setState({address2: json.body.address2 || ''})
            this.setState({city: json.body.city || ''})
            this.setState({postCode: json.body.postCode || ''})
            this.setState({state: json.body.state || ''})
            // this.setState({website: json.body.website || ''})
            this.setState({city: json.body.city || ''})

            this.setState({telephone: json.body.telephone  || ''})
            this.setState({officeTel: json.body.officeTel || ''})
            Modal.info({
              title: "Welcome back",
              content: "Please check your personal information and update if required.",
              onOk: () => {

              }
            });
          }
        })
        .catch((e) => {
          // this.props.history.push('/login')
        })
    }
    else {
      console.log('i undefined')
      // this.props.history.push('/login')
    }
  }

  checkEmail(email, field) {
    if (email !== '') {
      fetch(`${SERVER_URL}/checkEmail`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({email: email}),
      })
        .then(response => {
          if (response.status === 400) {
            this.setState({[field]: false})
          } else {
            this.setState({[field]: true})
          }
        })
    } else {
      this.setState({[field]: false})
    }
  }

  toggleGroup() {
    // this.setState({groupRegistration: !this.state.groupRegistration})
    return
  }

  checkEmailAdded() {
    const appendedVisitor = this.state.appendedVisitor
    const checkedEmail = []
    let existDuplicate = false
    for(let n in appendedVisitor) {
      appendedVisitor[n].existed = false
    }
    for(let n in appendedVisitor) {
      if (!checkedEmail.includes(appendedVisitor[n].email)) {
        checkedEmail.push(appendedVisitor[n].email)
        let res = appendedVisitor.filter(val => {
          return val.email === appendedVisitor[n].email && val.email
        })
        if(res.length > 1) {
          let numberSameEmail = 0
          for (let c in appendedVisitor) {
            if (appendedVisitor[c].email === appendedVisitor[n].email) {
              numberSameEmail ++
              if (numberSameEmail > 1) {
                appendedVisitor[c].existed = true
                existDuplicate = true
              }
            }
          }
        }
      }
    }
    this.setState({appendedVisitor: appendedVisitor})
    return existDuplicate
  }

  checkTextLength() {
    let allValidLength = true
    const array = this.state.invalidLengthText
    if (this.state.firstName.trim() === '') {
      allValidLength = false
    }
    if (this.state.lastName.trim() === '') {
      // if (!array.includes('lastName')) {
      //   array.push('lastName')
      // }
      this.setState({})
      allValidLength = false
    }
    if (this.state.designation.length < 3 || array.indexOf('designation') > 0) {
      this.setState({'invalid-length-designation': true})
      allValidLength = false
    } else {
      this.setState({'invalid-length-designation': false})
    }
    if (PHONE_REG.test(this.state.telephone) === false) {
      this.setState({'invalid-length-telephone': true})
      allValidLength = false
    } else {
      this.setState({'invalid-length-telephone': false})
    }
    console.log(this.state.officeTel + ' ' +  PHONE_REG.test(this.state.officeTel))
    if (this.state.officeTel && PHONE_REG.test(this.state.officeTel) === false) {
      this.setState({'invalid-length-officeTel': true})
      allValidLength = false
    } else {
      this.setState({'invalid-length-officeTel': false})
    }
    // if (this.state.city.length < 1 || array.indexOf('city') > 0) {
    //   if (!array.includes('city')) {
    //     array.push('city')
    //   }
    //   allValidLength = false
    // }
    if (this.state.company.length < 1 || array.indexOf('company') > 0) {
      this.setState({'invalid-length-company': true})
      allValidLength = false
    } else {
      this.setState({'invalid-length-company': false})
    }
    if (this.state.address1.length < 5 || array.indexOf('address1') > 0) {
      this.setState({'invalid-length-address1': true})
      allValidLength = false
    } else {
      this.setState({'invalid-length-address1': false})
    }
    // if (this.state.address2.length < 5 || array.indexOf('address2') < 0) {
    //   array.push('address2')
    //   allValidLength = false
    // }
    if (this.state.postCode.length < 5 || array.indexOf('postCode') > 0) {
      this.setState({'invalid-length-postCode': true})

      allValidLength = false
    } else {
      this.setState({'invalid-length-postCode': false})
    }
    if (this.state.state.length < 2 || array.indexOf('state') > 0) {
      this.setState({'invalid-length-state': true})
      allValidLength = false
    } else {
      this.setState({'invalid-length-state': false})
    }
    return allValidLength
  }

  render() {
    const that = this
    return (
      <div className="App">
        <header className="App-header backgroundLightGrey">
          <div className="card-header">
            <Logo/>
            <DateAddress/>
          </div>
          <div className="sectionHeader">
            <button
              onClick={() => this.navigateP1()}
              className={"button-default button-slanted button-slanted--left "
                + (this.state.page === 1 ? 'backgroundGreen' : 'backgroundGrey')}
            >
              <span className="button-slanted-content">Registration</span>
            </button>
            <button
              onClick={() => this.navigateP2()}
              className={"button-default button-slanted button-slanted--right "
                + (this.state.page === 2 ? 'backgroundGreen' : 'backgroundGrey')}
            >
              <span className="button-slanted-content">Visitor Profile</span>
            </button>
          </div>
          <div id="p1" className="page show">
            {this.state.groupRegistration ? <div className='roundedContainer' onClick={() => this.toggleGroup()}><img className="roundedImage clickable" src={require('../image/group.jpg')}/></div> : <div className='roundedContainer'><img className="roundedImage" src={require('../image/single.jpg')}/></div>}
            <span className="groupRegistrationButton">{this.state.groupRegistration ? 'Group / Company Registration' :  `${this.state.vType} Registration`}</span>
            <Select
              size="default"
              placeholder='Select a salutation'
              onChange={(value) => this.handleChange('salutation', value)}
              className={"selectClass " + (this.state.salutationRequired === true ? 'error' : '')}
              value={this.state.salutation}
              defaultValue=""
            >
              {salutationList.map(function(salutation){
                return <Option key={salutation.value} value={salutation.value}>{salutation.label}</Option>
              })}
              <Option value="" selected disabled hidden>Select a salutation</Option>
            </Select>
            {/* <div className='inputClass'>
              <Input
                className={(that.state.nameRequired && 'redPlaceholder')}
                placeholder={(that.state.nameRequired ? 'Name required' : 'Name')}
                value={this.state.name}
                onChange={(event) => this.handleChange('name', event.target.value)}
              />
              {(this.state.nonEnglishInputs.includes('name') || this.state.invalidLengthText.includes('name')) && <span className='error-message'>{this.state.nonEnglishInputs.includes('name') ? 'Please enter in english.': 'Name too short! Please Enter your full name.'}</span>}
            </div> */}
            <div className='inputClass2'>
              <div className='inputField'>
                <Input
                  className={(this.state.firstNameRequired && 'redPlaceholder')}
                  placeholder={(this.state.firstNameRequired ? 'First Name required' : 'First Name')}
                  value={this.state.firstName}
                  onChange={(event) => this.handleChange('firstName', event.target.value)}
                />
                {((this.state.nonEnglishInputs.includes('firstName') || this.state.firstName === '' || this.state['invalid-length-lastName']) && this.state.firstNameRequired) && <span className='error-message'>{this.state.nonEnglishInputs.includes('firstName') ? 'Please enter in english.': 'First Name too short'}</span>}
              </div>
              <div className='inputField'>
                <Input
                  className={(this.state.lastNameRequired && 'redPlaceholder')}
                  placeholder={(this.state.lastNameRequired ? 'Last Name required' : 'Last Name')}
                  value={this.state.lastName}
                  onChange={(event) => this.handleChange('lastName', event.target.value)}
                />
                {((this.state.nonEnglishInputs.includes('lastName') || this.state.lastName === '' || this.state['invalid-length-lastName']) && this.state.lastNameRequired) && <span className='error-message'>{this.state.nonEnglishInputs.includes('lastName') ? 'Please enter in english.': 'Last Name too short'}</span>}
              </div>
            </div>
            <Input
              disabled={this.state.emailDisabled}
              className={"inputClass " + (that.state.emailRequired && 'redPlaceholder')}
              placeholder={(that.state.emailRequired ? 'Email required' : 'Email')}
              value={this.state.email}
              onChange={(event) => this.handleChange('email', event.target.value)}
              onBlur={(event) => this.checkEmail(event.target.value, 'emailExists')}
            />
            {this.state.emailExists && <span className="emailExistsError">This email is already registered.</span>}
            {this.state.groupRegistration && <Input
              disabled={this.state.companyEmailDisabled}
              className={"inputClass " + (that.state.companyEmailRequired && 'redPlaceholder')}
              placeholder={(that.state.companyEmailRequired ? 'Company Email required' : 'Company Email')}
              value={this.state.companyEmail}
              onChange={(event) => this.handleChange('companyEmail', event.target.value)}
              onBlur={(event) => this.checkEmail(event.target.value, 'companyEmailExists')}
            />}
            {(this.state.groupRegistration && this.state.companyEmailExists) && <span className="emailExistsError">This email is already registered.</span>}
            <div className="inputClass">
              <Input
                className={(that.state.designationRequired && 'redPlaceholder')}
                placeholder={(that.state.designationRequired ? 'Designation required' : 'Designation')}
                value={this.state.designation}
                onChange={(event) => this.handleChange('designation', event.target.value)}
              />
              {(this.state.nonEnglishInputs.includes('designation') || this.state['invalid-length-designation']) && <span className='error-message'>{this.state.nonEnglishInputs.includes('designation')? 'Please enter in english.': 'Designation too short. Please enter your real designation.'}</span>}
            </div>
            <div className="inputClass">
              <Input
                className={(that.state.telephoneRequired && 'redPlaceholder')}
                placeholder={(that.state.telephoneRequired ? 'Handphone No. required' : 'Handphone No.')}
                value={this.state.telephone}
                onChange={(event) => this.handleChange('telephone', event.target.value)}
                maxLength={13}
              />
              {(this.state['invalid-length-telephone']) && <span className='error-message'>Invalid phone number.</span>}
            </div>
            <div className='inputClass'>
              <Input
                className={(that.state.companyRequired && 'redPlaceholder')}
                placeholder={(that.state.companyRequired ? 'Company required' : 'Company')}
                value={this.state.company}
                onChange={(event) => this.handleChange('company', event.target.value)}
              />
              {(this.state.nonEnglishInputs.includes('company') || this.state['invalid-length-company']) && <span className='error-message'>{this.state.nonEnglishInputs.includes('company') ? 'Please enter in english.': 'Company name too short! Please enter your full company name.'}</span>}
            </div>
            <div className='inputClass'>
              <Input
                className={(that.state.address1Required && 'redPlaceholder')}
                placeholder={(that.state.address1Required ? 'Address 1 required' : 'Address 1')}
                value={this.state.address1}
                onChange={(event) => this.handleChange('address1', event.target.value)}
              />
              {(this.state.nonEnglishInputs.includes('address1') || this.state['invalid-length-address1']) && <span className='error-message'>{this.state.nonEnglishInputs.includes('address1') ? 'Please enter in english.': 'Address too short! Please enter real address.'}</span>}
            </div>
            <div className='inputClass'>
              <Input
                placeholder={'Address 2'}
                value={this.state.address2}
                onChange={(event) => this.handleChange('address2', event.target.value)}
              />
              {(this.state.nonEnglishInputs.includes('address2') || this.state['invalid-length-address2']) && <span className='error-message'>{this.state.nonEnglishInputs.includes('address2') ? 'Please enter in english.': 'Address too short! Please enter real address.'}</span>}
            </div>
            <div className='inputClass'>
              <Input
                className={(that.state.cityRequired && 'redPlaceholder')}
                placeholder={(that.state.cityRequired ? 'City required' : 'City')}
                value={this.state.city}
                onChange={(event) => this.handleChange('city', event.target.value)}
              />
              {(this.state.nonEnglishInputs.includes('city') || this.state['invalid-length-city']) && <span className='error-message'>{this.state.nonEnglishInputs.includes('city') ? 'Please enter in english.': 'Address too short! Please city.'}</span>}
            </div>
            <div className='inputClass'>
              <Input
                className={(that.state.postCodeRequired && 'redPlaceholder')}
                placeholder={(that.state.postCodeRequired ? 'Post Code required' : 'Post Code')}
                value={this.state.postCode}
                onChange={(event) => this.handleChange('postCode', event.target.value)}
              />
              {(this.state.nonEnglishInputs.includes('postCode') || this.state['invalid-length-postCode']) && <span className='error-message'>{this.state.nonEnglishInputs.includes('postCode') ? 'Please enter in english.': 'Invalid postcode.'}</span>}
            </div>
            <div className='inputClass'>
              <Input
                className={(that.state.stateRequired && 'redPlaceholder')}
                placeholder={(that.state.stateRequired ? 'State required' : 'State')}
                value={this.state.state}
                onChange={(event) => this.handleChange('state', event.target.value)}
              />
              {(this.state.nonEnglishInputs.includes('state') || this.state['invalid-length-state']) && <span className='error-message'>{this.state.nonEnglishInputs.includes('state') ? 'Please enter in english.': 'State too short! Please insert real state.'}</span>}
            </div>
            <Select
              size="default"
              placeholder='Country'
              onChange={(value) => this.handleChange('country', value)}
              className="selectClass"
              value={this.state.country ? this.state.country: "Malaysia"}
              defaultValue="Malaysia"
            >
              {famousList.map(function(country){
                return <Option key={country.code} value={country.name}>{country.name}</Option>
              })}
              <Option key={'empty'} className="emptyOption"> </Option>
              {countryList.map(function(country){
                return <Option key={country.code} value={country.name}>{country.name}</Option>
              })}
            </Select>
            {/* <div className="inputClass">
              <Input
                placeholder='Website'
                value={this.state.website}
                onChange={(event) => this.handleChange('website', event.target.value)}
              />
              {this.state.nonEnglishInputs.includes('website') && <span className='error-message'>Please enter in english.</span>}
            </div> */}
            <div className="inputClass">
              <Input
                placeholder="Office Tel"
                value={this.state.officeTel}
                onChange={(event) => this.handleChange('officeTel', event.target.value)}
                maxLength={13}
              />
              {(this.state['invalid-length-officeTel']) && <span className='error-message'>Invalid phone number.</span>}
            </div>
            {this.state.groupRegistration && <div className="greyBorder">
              <ul className="visitorInstruction">
                <span className="subtitle">Group / Company Registration: </span>
                <li className="instruction">You can enter up to 10 visitors under the <b>same company.</b></li>
                <li className="instruction">Click on <Icon type="plus-circle" /> to add more Visitors button, or <Icon type="close-circle" /> to remove.</li>
              </ul>
              {this.state.appendedVisitor.map((appendedVisitor, index) => (
                <div key={`visitor${index}`} className='appendedVisitorDiv show'>
                  <h3 className="header">
                    Visitor <Icon type="close-circle" onClick={() => that.removeVisitor(index)}/>
                  </h3>
                  <Input
                    className={"inputClass " + (that.state.appendedVisitor[index].nameRequired  && 'redPlaceholder')}
                    placeholder={(that.state.appendedVisitor[index].nameRequired ? 'Name required' : 'Name')}
                    value={that.state.appendedVisitor[index].name}
                    onChange={(event) => that.handleText(index, 'name', event)}
                  />
                  <div className={"inputClass taLeft"}>
                    <div className="row">
                      <Input
                        className={"inputCompanyEmailClass " + (that.state.appendedVisitor[index].emailRequired && 'redPlaceholder')}
                        placeholder={(that.state.appendedVisitor[index].emailRequired ? 'Email required' : 'Email')}
                        value={that.state.appendedVisitor[index].email}
                        onBlur={(event) => this.checkEmailAdded()}
                        onChange={(event) => that.handleText(index, 'email', event)}
                      />
                      <span>@{this.state.companyDomain}</span>
                    </div>
                    {that.state.appendedVisitor[index].existed? <div className='invalid-text'> Email existed</div>: ''}
                  </div>
                  {that.state.appendedVisitor[index].emailError && <span className="emailExistsError">Please enter ONLY your email name without @domain.com</span>}
                  <Input
                    className={"inputClass " + (that.state.appendedVisitor[index].designationRequired && 'redPlaceholder')}
                    placeholder={(that.state.appendedVisitor[index].designationRequired ? 'Designation required' : 'Designation')}
                    value={that.state.appendedVisitor[index].designation}
                    onChange={(event) => that.handleText(index, 'designation', event)}
                  />
                  <Input
                    className={"inputClass " + (that.state.appendedVisitor[index].telephoneRequired && 'redPlaceholder')}
                    placeholder={(that.state.appendedVisitor[index].telephoneRequired ? 'Handphone No. required' : 'Handphone No.')}
                    value={that.state.appendedVisitor[index].telephone}
                    onChange={(event) => that.handleText(index, 'telephone', event)}
                  />
                  {that.state['invalid-length-telephone'] && <span className="error-message">Invalid phone number</span>}
                  <hr/>
                </div>
              ))}
              <Button className="addVisitorButton" onClick={() => this.addVisitor()}>
                <Icon type="plus-circle" /> Add visitor
              </Button>
            </div> }

            <Button className="nextButton" onClick={() => this.navigateP2()}>
              Next <Icon type="right" />
            </Button>
          </div>
          <br/>
          <br/>
          <br/>
          <br/>
        </header>
      </div>
    );
  }
}

export default Register_v2;
