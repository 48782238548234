import React from 'react';
import './../App.css';
import { Button, Checkbox, Radio } from 'antd';
import { LIST_0, LIST_1, LIST_2, LIST_3, LIST_4, LIST_5, LIST_6 } from './list';
import {SERVER_URL} from '../constant'
import updatePage from './Update';
import DateAddress from '../components/dateAddress';
import Logo from '../components/logo';
class Register_v2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      disableUpdateBtn: false
    };
    this.editType = ''
  }

  navigateP1() {
    this.props.history.push('/register')
  }

  register() {
    const that = this;
    let errorPage2 = false
    const profile = {}
    for (const key in this.state) {
      if (key.startsWith('selected-')) {
        if (typeof this.state[key] === 'undefined') {
          this.setState({errorPage2: true})
          errorPage2 = true
        }
        profile[key] = this.state[key];
      }
    }
    if (typeof this.state['selected-0'] === 'undefined') {
      this.setState({errorPage2: true})
      errorPage2 = true
    } else if (typeof this.state['selected-1'] === 'undefined') {
      this.setState({errorPage2: true})
      errorPage2 = true
    } else if (typeof this.state['selected-2'] === 'undefined') {
      this.setState({errorPage2: true})
      errorPage2 = true
    } else if (typeof this.state['selected-3'] === 'undefined') {
      this.setState({errorPage2: true})
      errorPage2 = true
    } else if (typeof this.state['selected-5'] === 'undefined') {
      this.setState({errorPage2: true})
      errorPage2 = true
    } else if (typeof this.state['selected-6'] === 'undefined') {
      this.setState({errorPage2: true})
      errorPage2 = true
    } else if (typeof this.state['selected-7'] === 'undefined' || this.state['selected-7'].length === 0) {
      this.setState({errorPage2: true})
      errorPage2 = true
    }

    // const
    console.log(`errorPage2 (${errorPage2}) ==> ` + JSON.stringify(profile))

    if (!errorPage2) {
      this.setState({disableUpdateBtn: true})
      const updates = []
      const registerTimestamp = Date.now()
      for (let i = 0; i < this.props.location.state.updates.length; i++) {
        const update = {
          i: this.props.location.state.updates[i].i || '',
          platform: this.props.location.state.updates[i].platform,
          name: this.props.location.state.updates[i].name || this.props.location.state.updates[i].firstName + ' ' + this.props.location.state.updates[i].lastName,
          firstName: this.props.location.state.updates[i].firstName || '',
          lastName: this.props.location.state.updates[i].lastName || '',
          email: this.props.location.state.updates[i].email,
          companyEmail: this.props.location.state.updates[i].companyEmail,
          designation: this.props.location.state.updates[i].designation,
          telephone: this.props.location.state.updates[i].telephone,
          city: this.props.location.state.updates[i].city,
          company: this.props.location.state.updates[i].company,
          address1: this.props.location.state.updates[i].address1,
          address2: this.props.location.state.updates[i].address2,
          postCode: this.props.location.state.updates[i].postCode,
          state: this.props.location.state.updates[i].state,
          country: this.props.location.state.updates[i].country,
          // website: this.props.location.state.updates[i].website,
          salutation: this.props.location.state.updates[i].salutation,
          officeTel: this.props.location.state.updates[i].officeTel,
          profile,
          // vType: 'Visitor',
        }
        if (this.state.editType !== 'update') {
          update.registerTimestamp = registerTimestamp
        }
        updates.push(update)
      }
      fetch(`${SERVER_URL}/register`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updates),
      })
        .then(response => response.json())
        .then((json) => {
          that.setState({disableUpdateBtn: false})
          const profile = {
            i: json.body,
          }
          that.props.history.push('/barcode', profile)
        })
    } else {
      window.scrollTo(0, 0);
    }
  }

  checkboxOnChange(event, field) {
    this.setState({[field]: event.target.value})
  }

  checkboxOnChangeArray(event, field) {
    const data = this.state[field] ? this.state[field] : []
    if (data.includes(event.target.value)) {
      const index = data.indexOf(event.target.value)
      data.splice(index, 1)
      for (const key in this.state) {
        if (key.startsWith(field + '-')) {
          this.setState({[key]: undefined});
        }
      }
    } else {
      data.push(event.target.value)
    }
    this.setState({[field]: data})
  }

 checkboxMultipleOnChange(event, field) {
    this.setState(this.state[field])
  }

  boxChecked(value ,field) {
    const data = this.state[field]
    let result = []
    if (data) {
      result = Object.keys(data).map((key) => data[key])
    }
    return result.includes(value) ? true : false
  }

  getCategories(item) {
    const array = []
    for (const cat of item) {
      if (!array.includes(cat.category)) {
        array.push(cat.category)
      }
    }
    return array
  }

  // getSubCategories(stateKey, catKey, item) {
  //   const array = []
  //   const checkArray = this.state[stateKey];
  //   for (const subCat of item) {
  //     if (catKey) {
  //       // for (const v of subCat.availableIn) {
  //       if (!array.includes(subCat.subCategory) && catKey === subCat.category) {
  //         array.push(subCat.subCategory)
  //       }
  //       // }
  //     } else {
  //       array.push(subCat.subCategory)
  //     }
  //   }
  //   return array;
  // }


  componentDidMount() {
    if (this.props.location.state && this.props.location.state.type === 'update') {
      this.setState({editType: this.props.location.state.type})
      // const profile = this.props.location.state.updates
      const profile = this.props.location.state.updates

      fetch(`${SERVER_URL}/getProfileData`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(profile),
      }).then(response => response.json())
      .then((json) => {
        const data = json.body
        for (let item in data) {
          if (item === 'profile') {
            const profile = data[item]

            for (let key in profile) {
              this.setState({[key]: profile[key]})
            }
          } else {
            this.setState({[item]: data[item]})
          }
        }
      })
      // this.setState({email: this.props.location.state.e})
    } else {
      this.editType = 'register'
    }
  }
  render() {
    return (
      <div className="App">
        <header className="App-header backgroundLightGrey">
          <div className="card-header">
            <Logo/>
            <DateAddress/>
          </div>
          <div className="sectionHeader">
            {this.editType === 'register' &&
              <button
                onClick={() => this.navigateP1()}
                className={"button-default button-slanted button-slanted--left backgroundGrey"}
              >
                <span className="button-slanted-content">Registration</span>
              </button>
            }
            {this.editType === 'register' &&
              <button
                className={"button-default button-slanted button-slanted--right backgroundGreen"}
              >
                <span className={"button-slanted-content"}>Visitor Profile</span>
              </button>
            }
            {this.editType === 'update' &&
              <button
                className={"button-default button-title backgroundGreen"}
              >
                <span className={"button-title-content"}>Visitor Profile</span>
              </button>
            }
          </div>
          <div id="p1" className="page show">
            {this.state.errorPage2 && <span className="page2error">* All field is mandotary.</span>}
              <div className="profile-content">
                <span className="checkboxtitle">{LIST_0.label}</span>
                {LIST_0.multiOption &&
                  <div>
                    <div className="selectDiv">
                      <span className="selectSpan">Select one or more</span>
                    </div>
                    <div className="checkboxGroup">
                      {LIST_0.options.map((name, index) => (
                        <Checkbox key={name} className="checkboxClass" value={2 ** index} checked={this.boxChecked(2 ** index ,'selected-0')} onChange={(event) => this.checkboxOnChangeArray(event, 'selected-0')}><span className="checkboxSpan">{name}</span></Checkbox>
                      ))}
                    </div>
                  </div>
                }
                {
                  !LIST_0.multiOption &&
                  <div className="profile-content">
                    <Radio.Group className="radioGroupClass" onChange={(event) => this.checkboxOnChange(event, 'selected-0')} value={this.state['selected-0']}>
                      {LIST_0.options.map((name, index) => (
                        <Radio key={index} className="radioButton" value={2 ** index}><span className="radioSpan">{name}</span></Radio>
                      ))}
                    </Radio.Group>
                  </div>
                }
              </div>
              <hr></hr>
              <div className="profile-content">
                <span className="checkboxtitle">{LIST_1.label}</span>
                {LIST_1.multiOption &&
                  <div>
                    <div className="selectDiv">
                      <span className="selectSpan">Select one or more</span>
                    </div>
                    <div className="checkboxGroup">
                      {LIST_1.options.map((name, index) => (
                        <Checkbox key={name} className="checkboxClass" value={2 ** index} checked={this.boxChecked(2 ** index ,'selected-1')} onChange={(event) => this.checkboxOnChangeArray(event, 'selected-1')}><span className="checkboxSpan">{name}</span></Checkbox>
                      ))}
                    </div>
                  </div>
                }
                {
                  !LIST_1.multiOption &&
                  <div className="profile-content">
                    <Radio.Group className="radioGroupClass" onChange={(event) => this.checkboxOnChange(event, 'selected-1')} value={this.state['selected-1']}>
                      {LIST_1.options.map((name, index) => (
                        <Radio key={index} className="radioButton" value={2 ** index}><span className="radioSpan">{name}</span></Radio>
                      ))}
                    </Radio.Group>
                  </div>
                }
              </div>
              <hr></hr>
              <div className="profile-content">
                <span className="checkboxtitle">{LIST_2.label}</span>
                {LIST_2.multiOption &&
                  <div>
                    <div className="selectDiv">
                      <span className="selectSpan">Select one or more</span>
                    </div>
                    <div className="checkboxGroup">
                      {LIST_1.options.map((name, index) => (
                        <Checkbox key={name} className="checkboxClass" value={2 ** index} checked={this.boxChecked(2 ** index ,'selected-2')} onChange={(event) => this.checkboxOnChangeArray(event, 'selected-2')}><span className="checkboxSpan">{name}</span></Checkbox>
                      ))}
                    </div>
                  </div>
                }
                {
                  !LIST_2.multiOption &&
                  <div className="profile-content">
                    <Radio.Group className="radioGroupClass" onChange={(event) => this.checkboxOnChange(event, 'selected-2')} value={this.state['selected-2']}>
                      {LIST_2.options.map((name, index) => (
                        <Radio key={index} className="radioButton" value={2 ** index}><span className="radioSpan">{name}</span></Radio>
                      ))}
                    </Radio.Group>
                  </div>
                }
              </div>
              <hr></hr>
              <div className="profile-content">
                <span className="checkboxtitle">{LIST_3.label}</span>
                {
                  LIST_3.categoryOptions && LIST_3.multiOption &&
                  <div className="profile-content">
                    <div className="selectDiv">
                      <span className="selectSpan">Select one or more</span>
                    </div>
                    <div className="checkboxGroup">
                      { LIST_3.options.map((eventName, n) => (
                        <div key={'selected-3-' + n}>
                          <Checkbox key={'selected-3-' + n} className="checkboxClass" value={eventName.key} checked={this.boxChecked(eventName.key ,'selected-3')} onChange={(event) => this.checkboxOnChangeArray(event, 'selected-3',)} ><span className="checkboxSpan" style={{ fontWeight: 600}}>{eventName.label}</span></Checkbox>

                        </div>
                      ))}
                    </div>
                  </div>
                }
              </div>
              <hr/>
              <div className="profile-content">
                <span className="checkboxtitle">{LIST_3.categoryOptions.label}</span>
                <div className="selectDiv">
                  <span className="selectSpan">Select one or more</span>
                </div>
                {<div>
                  {
                    <div className="checkboxGroup">
                      {
                        LIST_3.categoryOptions.options && LIST_3.categoryOptions.options.map((name, c) => (
                          // <Checkbox key={index} className="checkboxClass" value={catName} checked={this.checkboxWithCategory('selected-3', eventName, 'category', catName)} onChange={(event) => this.checkboxOnChangeArray(event, 'selected-3')}><span className="checkboxSpan">{catName}</span></Checkbox>
                          <div key={`selected-7-${c}`}>
                            <Checkbox key={`selected-7`} className="checkboxClass" value={name.value} checked={this.boxChecked(name.value ,`selected-7`)} onChange={(event) => this.checkboxOnChangeArray(event,`selected-7`,)} ><span className="checkboxSpan">{name.label}</span></Checkbox>
                            {<div style={{ paddingLeft: '20px'}}>
                              {
                                (this.state[`selected-7`] && this.state[`selected-7`].includes(name.value)) && (
                                  <div>{ name.subCategory && name.subCategory.map((subName, s) => (
                                    <Checkbox key={`selected-7-${s}`} className="checkboxClass" value={subName.value} checked={this.boxChecked(subName.value ,`selected-7-${c}-subcat`)} onChange={(event) => this.checkboxOnChangeArray(event,`selected-7-${c}-subcat`,)} ><span className="checkboxSpan">{subName.label}</span></Checkbox>
                                  ))}</div>
                                )
                              }
                            </div>}
                          </div>
                        ))
                      }
                    </div>
                  }
                </div>}
              </div>
              <hr></hr>
              <div className="profile-content">
                <span className="checkboxtitle">{LIST_4.label}</span>
                {LIST_4.multiOption &&
                  <div>
                    <div className="selectDiv">
                      <span className="selectSpan">Select one or more</span>
                    </div>
                    <div className="checkboxGroup">
                      {LIST_1.options.map((name, index) => (
                        <Checkbox key={name} className="checkboxClass" value={2 ** index} checked={this.boxChecked(2 ** index ,'selected-4')} onChange={(event) => this.checkboxOnChangeArray(event, 'selected-4')}><span className="checkboxSpan">{name}</span></Checkbox>
                      ))}
                    </div>
                  </div>
                }
                {
                  !LIST_4.multiOption &&
                  <div className="profile-content">
                    <Radio.Group className="radioGroupClass" onChange={(event) => this.checkboxOnChange(event, 'selected-4')} value={this.state['selected-4']}>
                      {LIST_4.options.map((name, index) => (
                        <Radio key={index} className="radioButton" value={2 ** index}><span className="radioSpan">{name}</span></Radio>
                      ))}
                    </Radio.Group>
                  </div>
                }
              </div>
              <hr></hr>
              <div className="profile-content">
                <span className="checkboxtitle">{LIST_5.label}</span>
                {LIST_5.multiOption &&
                  <div>
                    <div className="selectDiv">
                      <span className="selectSpan">Select one or more</span>
                    </div>
                    <div className="checkboxGroup">
                      {LIST_5.options.map((name, index) => (
                        <Checkbox key={name} className="checkboxClass" value={2 ** index} checked={this.boxChecked(2 ** index ,'selected-5')} onChange={(event) => this.checkboxOnChangeArray(event, 'selected-5')}><span className="checkboxSpan">{name}</span></Checkbox>
                      ))}
                    </div>
                  </div>
                }
                {
                  !LIST_5.multiOption &&
                  <div className="profile-content">
                    <Radio.Group className="radioGroupClass" onChange={(event) => this.checkboxOnChange(event, 'selected-5')} value={this.state['selected-5']}>
                      {LIST_5.options.map((name, index) => (
                        <Radio key={index} className="radioButton" value={2 ** index}><span className="radioSpan">{name}</span></Radio>
                      ))}
                    </Radio.Group>
                  </div>
                }
              </div>
              <hr></hr>
              <div className="profile-content">
                <span className="checkboxtitle">{LIST_6.label}</span>
                {LIST_6.multiOption &&
                  <div>
                    <div className="selectDiv">
                      <span className="selectSpan">Select one or more</span>
                    </div>
                    <div className="checkboxGroup">
                      {LIST_6.options.map((name, index) => (
                        <Checkbox key={name} className="checkboxClass" value={2 ** index} checked={this.boxChecked(2 ** index ,'selected-6')} onChange={(event) => this.checkboxOnChangeArray(event, 'selected-6')}><span className="checkboxSpan">{name}</span></Checkbox>
                      ))}
                    </div>
                  </div>
                }
                {
                  !LIST_6.multiOption &&
                  <div className="profile-content">
                    <Radio.Group className="radioGroupClass" onChange={(event) => this.checkboxOnChange(event, 'selected-6')} value={this.state['selected-6']}>
                      {LIST_6.options.map((name, index) => (
                        <Radio key={index} className="radioButton" value={2 ** index}><span className="radioSpan">{name}</span></Radio>
                      ))}
                    </Radio.Group>
                  </div>
                }
              </div>
            <hr/>
            <br/>
            <div style={{ flexDirection: 'row', width: '90%' }}>
              <Button onClick={() => this.navigateP1()} className={`backButton ${this.state.editType === 'update'? 'hide' : ''}`}>Back</Button>
              <Button onClick={() => this.register()} className="registerButton" disabled={this.state.disableUpdateBtn}>{this.state.editType === 'update'? 'Update' : 'Register'}</Button>
            </div>
          </div>
          <br/>
          <br/>
          <br/>
          <br/>
        </header>
      </div>
    );
  }
}

export default Register_v2;
